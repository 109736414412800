import {CountUp} from "~/media/libs/countup.js/dist/countUp.min.js";

!function(){
    const priceToggleElement = document.querySelector('[data-toggle="price"]');
    if (priceToggleElement && typeof CountUp !== "undefined") {    
        function togglePrice(targetSelector) {
            const isAnnual = priceToggleElement.checked;
            const targetNodes = document.querySelectorAll(targetSelector);
      
            targetNodes.forEach((node) => {
                const annualValue = parseFloat(node.dataset.annual || node.dataset.payg);
                const monthlyValue = parseFloat(node.dataset.monthly || node.dataset.payg);
                const duration = parseInt(node.dataset.duration) || 1;
                const options = node.dataset.options
                ? {
                    ...JSON.parse(node.dataset.options),
                    duration,
                  }
                : {
                    duration,
                };
                const endValue = isAnnual ? annualValue : monthlyValue;
                let startValue = isAnnual ? monthlyValue : annualValue;
                if (startValue == endValue) {
                    startValue = 0;
                }

                let countUpOptions = {
                    ...options,
                    startVal: startValue,
                };

                const additionalCredits = node.dataset.annualAdditionalCredits;

                if (isAnnual && additionalCredits) {
                    countUpOptions = {
                        ...countUpOptions,
                        suffix: ` + ${additionalCredits}`,
                    };
                }

                const countUpInstance = new CountUp(node, endValue, countUpOptions);

                if (!countUpInstance.error) {
                    countUpInstance.start();
                } else {
                    console.error(countUpInstance.error);
                }
            });
        }

        const targetSelector = priceToggleElement.dataset.target;
        togglePrice(targetSelector);
        togglePrice('.price-right')

        priceToggleElement.addEventListener("change", (event) => {
            togglePrice(targetSelector);
            togglePrice('.price-right');
        });

        const pricingPlanRows = document.querySelectorAll('.pricing-plan-table tr');
        for (const node of pricingPlanRows) {
            node.addEventListener('click', () => {
                togglePrice('.price-right');
            })
        }

        const navTab = document.getElementById('pricing-plan-nav-tab');
        navTab.addEventListener('click', () => {
            togglePrice(targetSelector);
            togglePrice('.price-right');
        })
    }
}()